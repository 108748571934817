import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from "./NavBar/NavBar";
import logo from './NavBar/img/logo.svg';
import Button from '../../../UI/Button';
import { removeUserFromLocalStorage } from '../../../../utils/storage';

const Header = ({ onLogout }) => {
    return (
      <header className='App-header container'>
        <nav className='navbar navbar-expand-lg'>
          <a className='navbar-brand flex-grow-1' href='#/test'>
            <img
              src={logo}
              width='150'
              height='80'
              className='d-inline-block align-top'
              alt=''
            />
          </a>
          {/* <NavBar /> */}
          <Button
            cta={"Cerrar Sesión"}
            classColor={"btn-outline-danger"}
            onHandleClick={onLogout} // Usar la función de redirección directamente desde prop
          />
        </nav>
      </header>
    );
  };

export default Header;

