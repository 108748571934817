import React, { useState, useRef } from 'react';
import Button from '../../UI/Button';
import Alert from '../../UI/Alert';
import { fetchAgregarPersona } from '../../../services/censoAPI';

const NewPerson = ({ userLogged, obtengoDeptos, obtengoOcupaciones, obtengoCiudadesDepto, handleDepartamentoChange, updatePersonList }) => {
  const [message, setMessage] = useState('');
  const [classColor, setClassColor] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const inputPersonUsuario = useRef();
  const inputPersonDepto = useRef();
  const inputPersonCiudad = useRef();
  const inputPersonFecha = useRef();
  const inputPersonOcupacion = useRef();

  const [nuevaPersona, setNuevaPersona] = useState({
    nombre: '',
    departamento: '',
    ciudad: '',
    fechaNacimiento: '',
    ocupacion: '',
  });

  const _handleCensarClick = async (e) => {
    
    e.preventDefault();
    if (userLogged && userLogged.userData) {
      if (!_isEmptyForm()) {
        
        // Obtenemos los valores del formulario utilizando las referencias
        const nombre = inputPersonUsuario.current.value;
        const departamento = inputPersonDepto.current.value;
        const ciudad = inputPersonCiudad.current.value;
        const fechaNacimiento = inputPersonFecha.current.value;
        const ocupacion = inputPersonOcupacion.current.value;

        
        try {
          // Llamada a la API para agregar persona
          const response = await fetchAgregarPersona(
            nombre,
            departamento,
            ciudad,
            fechaNacimiento,
            ocupacion,
            userLogged.userData
          );

          // Actualizar la lista de personas en el Dashboard
          updatePersonList({
            idCenso: response.idCenso, 
            nombre: nombre,
            departamento: departamento,
            ciudad: ciudad,
            fechaNacimiento: fechaNacimiento,
            ocupacion: ocupacion,
          });

          // Resto del código...
        } catch (error) {
          // En caso de error en la llamada a la API
          console.error('Error al agregar persona:', error);

          setShowAlert(true);
          setMessage('Error al agregar persona');
          setClassColor('danger');
        }
      } else {
        setShowAlert(true);
        setMessage('Por favor complete los campos');
        setClassColor('danger');
      }
    } else {
      console.error('userLogged es nulo o no tiene la propiedad "id"');
    }
  };

  const _isEmptyForm = () => {
    return (
      inputPersonUsuario.current.value.length === 0 ||
      inputPersonDepto.current.value.length === 0 ||
      inputPersonCiudad.current.value.length === 0 ||
      inputPersonFecha.current.value.length === 0 ||
      inputPersonOcupacion.current.value.length === 0
    );
  };

  const _onHandleChange = () => {
    if (!_isEmptyForm()) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  return (
    <>
      {showAlert ? <Alert classColor={classColor} message={message} /> : ''}
      <div className='card box-shadow radius p-5'>
        <h4 className='mb-4'>Censar nueva persona</h4>
        <form>
          <div className="form-group">
            <label>¿Cómo te llamas?</label>
            <input type="text" className="form-control" placeholder="Nombre" onChange={_onHandleChange} ref={inputPersonUsuario} />
          </div>
          <div className="form-group">
            <label>¿De qué departamento?</label>
            <select className="form-control" onChange={handleDepartamentoChange} ref={inputPersonDepto}>
              {obtengoDeptos.map((depto) => (
                <option key={depto.id} value={depto.id}>
                  {depto.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>¿De qué ciudad sos?</label>
            <select className="form-control" onChange={_onHandleChange} ref={inputPersonCiudad}>
              {obtengoCiudadesDepto.map((ciudades) => (
                <option key={ciudades.id} value={ciudades.nombre}>
                  {ciudades.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>¿Cuando naciste?</label>
            <input type="date" className="form-control" placeholder="Fecha de nacimiento" onChange={_onHandleChange} ref={inputPersonFecha} />
          </div>
          <div className="form-group">
            <label>¿A qué te dedicas?</label>
            <select className='form-control' onChange={_onHandleChange} ref={inputPersonOcupacion}>
              {obtengoOcupaciones.map((ocupacion) => (
                <option key={ocupacion.id} value={ocupacion.nombre}>
                  {ocupacion.ocupacion}
                </option>
              ))}
            </select>
          </div>
          <Button
            cta={"Nuevo censo"}
            classColor={"button-color mt-3 w-100"}
            onHandleClick={_handleCensarClick}
            disabled={btnDisabled}
          />
        </form>
      </div>
    </>
  );
};

export default NewPerson;
