import React from 'react'
import RegisterForm from './RegisterForm'

import { Link, useNavigate } from 'react-router-dom'

const Register = ({onRegister}) => {
return (
    <section className='justify-content-center d-flex min-vh-100 align-items-center '>
        <div className='card p-3 border box-shadow col-3 radius'>
            
            <h3 className='primary-color text-center pt-2'><strong>Registro</strong></h3>
            <section className='card-body'>
            <RegisterForm onRegister={onRegister}/>
            <Link className='d-block mt-3 text-center' to={'/login'}>Iniciar Sesión</Link>
            </section>
        </div>
    </section>
    )
}

export default Register

