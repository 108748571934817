import React from 'react';
import PieTotalCenso from './PieTotalCenso';

const CensadosTotales = ({ totalCensados, totalCensadosEnMontevideo, totalPersonasEnLaApp }) => {
  return (
    <>
      <div className='col-4'>
        <div className='card p-4 bg-custom-card text-white text-center'>
          <p><strong><big>Total de personas censadas: <badge>{totalCensados}</badge></big></strong></p>
        </div>
      </div>
      <div className='col-4'>
        <div className='card p-4 bg-custom-card text-white text-center'>
          <p><strong><big>Total de personas censadas en Montevideo: <badge>{totalCensadosEnMontevideo}</badge></big></strong></p>
        </div>
      </div>
      <div className='col-4'>
        <div className='card p-4 bg-custom-card text-white text-center'>
          <p><strong><big>Personas censadas por vos y los censados por toda la aplicación:</big></strong></p>
          <PieTotalCenso totalCensados={totalCensados} totalPersonasEnLaApp={totalPersonasEnLaApp} />
        </div>
      </div>
      </>
  );
};

export default CensadosTotales;
