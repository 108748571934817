import React, { useState, useEffect } from 'react';

const Tiempo = () => {
  const [diasRestantes, setDiasRestantes] = useState(null);

  useEffect(() => {
    const fechaFinalCenso = new Date('2023-08-31');
    const fechaActual = new Date();
    const diferencia = fechaFinalCenso - fechaActual;    
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));    
    setDiasRestantes(dias);
  }, []);

  return (
    <>
      {diasRestantes !== null ? (
        <div className='tiempo card p-4 text-white text-center'><p><strong><big>Tiempo restante</big></strong></p> <strong><badge className="mt-0">{diasRestantes} días</badge></strong> </div>
      ) : (
        <div>Cargando...</div>
      )}
    </>
  );
};

export default Tiempo;