import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ user, children, redirectTo }) => {
  
  if (user) {
    return children
  } else {
    return <Navigate to={redirectTo} />
  }
}

export default PrivateRoute