const OBLIGATORIO_API_USER = 'ObligatorioAppUser';

const setUserToLocalStorage = (userData) => {
  localStorage.setItem(OBLIGATORIO_API_USER, JSON.stringify(userData));
};

const removeUserFromLocalStorage = () => {
  localStorage.removeItem(OBLIGATORIO_API_USER);
};

const getItemFromLocalStorage = (key) => {
  if(!key){
    key = OBLIGATORIO_API_USER;
  }
  if(!localStorage.getItem(key)){
    return null;
  }
  const data = {
    userData: JSON.parse(localStorage.getItem(key))
  };
  return data
};

export {
  getItemFromLocalStorage,
  removeUserFromLocalStorage,
  setUserToLocalStorage,
};
