import Bar from './Bar'
import Pie from './Pie'

const Charts = ({personas}) => { 

  // por depto
  const _totalTacuarembo = () => personas.filter((persona) => persona.departamento === '3221').length;
  const _totalSalto = () => personas.filter((persona) => persona.departamento === '3220').length;
  const _totalSoriano = () => personas.filter((persona) => persona.departamento === '3219').length;
  const _totalMontevideo = () => personas.filter((persona) => persona.departamento === '3218').length;
  const _totalFlorida = () => personas.filter((persona) => persona.departamento === '3217').length;
  const _totalRocha = () => personas.filter((persona) => persona.departamento === '3216').length;
  const _totalLavalleja = () => personas.filter((persona) => persona.departamento === '3215').length;
  const _totalTreinaYtres = () => personas.filter((persona) => persona.departamento === '3214').length;
  const _totalCanelones = () => personas.filter((persona) => persona.departamento === '3213').length;
  const _totalPaysandu = () => personas.filter((persona) => persona.departamento === '3212').length;
  const _totalCerroLargo = () => personas.filter((persona) => persona.departamento === '3211').length;
  const _totalRioNegro = () => personas.filter((persona) => persona.departamento === '3210').length;
  const _totalDurazno = () => personas.filter((persona) => persona.departamento === '3209').length;
  const _totalColonia = () => personas.filter((persona) => persona.departamento === '3208').length;
  const _totalRivera = () => personas.filter((persona) => persona.departamento === '3207').length;
  const _totalMaldonado = () => personas.filter((persona) => persona.departamento === '3206').length;
  const _totalArtigas = () => personas.filter((persona) => persona.departamento === '3205').length;
  const _totalSanJose = () => personas.filter((persona) => persona.departamento === '3204').length;
  const _totalFlores = () => personas.filter((persona) => persona.departamento === '3203').length;
    
  // por ocupacion
  const _dependiente = () => personas.filter((persona) => persona.ocupacion === 'Empleado dependiente').length;
  const _independiente = () => personas.filter((persona) => persona.ocupacion === 'Empleado independiente').length;
  const _publico = () => personas.filter((persona) => persona.ocupacion === 'Empleado público').length;
  const _comerciante = () => personas.filter((persona) => persona.ocupacion === 'Comerciante').length;
  const _estudiante = () => personas.filter((persona) => persona.ocupacion === 'Estudiante').length;
  const _emprendedor = () => personas.filter((persona) => persona.ocupacion === 'Emprendedor').length;
  const _notrabaja = () => personas.filter((persona) => persona.ocupacion === 'No trabaja').length;
  
  return (   
        <>
        <div className='col-8 mt-4'>
          <div className='card p-4 bg-custom-card text-white'>
            <div className='card-body'>
              <p><strong><big>Resultados de departamento:</big></strong></p>
              <Bar
                Montevideo={_totalMontevideo()}
                Tacuarembó={_totalTacuarembo()}
                Salto={_totalSalto()}
                Soriano={_totalSoriano()}
                Florida={_totalFlorida()}
                Rocha={_totalRocha()}
                Lavalleja={_totalLavalleja()}
                Treina_Y_Tres={_totalTreinaYtres()}
                Canelones={_totalCanelones()}
                Paysandú={_totalPaysandu()}
                Cerro_Largo={_totalCerroLargo()}
                Río_Negro={_totalRioNegro()}
                Durazno={_totalDurazno()}
                Colonia={_totalColonia()}
                Rivera={_totalRivera()}
                Maldonado={_totalMaldonado()}
                Artigas={_totalArtigas()}
                San_José={_totalSanJose()}
                Flores={_totalFlores()}
              />
            </div>
          </div>
        </div>
        <div className='col-4 mt-4'>
          <div className='card p-4 bg-custom-card text-white text-center'>
            <div className='card-body'>
              <p><strong><big>Resultados de ocupación:</big></strong></p>
              <Pie
                Dependiente={_dependiente()}
                Independiente={_independiente()}
                Público={_publico()}
                Comerciante={_comerciante()}
                Estudiante={_estudiante()}
                Emprendedor={_emprendedor()}
                No_trabaja={_notrabaja()}
              />
            </div>
          </div>
        </div>    
        </>   
  )
}

export default Charts
