
import 'bootstrap-css-only'
import React, { useRef, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Dashboard from '../Pages/Dashboard';
import Register from '../Pages/Register';
import Login from '../Pages/Login';
import { getItemFromLocalStorage } from '../../utils/storage';
import PrivateRoute from '../Pages/PrivateRoute';
import { removeUserFromLocalStorage } from '../../utils/storage';
import { BrowserRouter as Router } from 'react-router-dom';



const App = () => {
  const storedUser = getItemFromLocalStorage('ObligatorioAppUser')
  const [userLogged , setUserLogged] = useState(storedUser)

  const _onLogin = (userData) => {
    setUserLogged(userData)
  }

  const _onRegister = (userData) => {
    setUserLogged(userData)
  }

  const _onLogout = () => {
    setUserLogged(null);
    removeUserFromLocalStorage();
  };

  return (
    <div className='App'>
      <Routes>
        <Route
          path='/'
          element={<Login onLogin={_onLogin} user={userLogged} />}
        />
        <Route
          path='/login'
          element={
            <Login
              onLogin={_onLogin}
              user={userLogged}
              onLogout={_onLogout}
            />
          }
        />
        <Route path='/register' element={<Register onLogin={_onRegister} />} />
  
        <Route
          path='/dashboard'
          element={
            <PrivateRoute user={userLogged} redirectTo='/login'>
              <Dashboard userLogged={userLogged} onLogout={_onLogout} /> {/* Pasar la función onLogout a Dashboard */}
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
        }

export default App;

