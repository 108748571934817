import React, { useState, useEffect } from 'react';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const departamentos = [
  {
    id: 3203,
    nombre: 'Flores',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 6,
    iso2: 'FS',
    tipo: null,
    latitud: -33.57337530000000214158717426471412181854248046875,
    longitud: -56.89450279999999793290044181048870086669921875,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: 'Q16578'
  },
  {
    id: 3204,
    nombre: 'San José',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 16,
    iso2: 'SJ',
    tipo: null,
    latitud: -34.33831260000000185073076863773167133331298828125,
    longitud: -56.73984329999999687288436689414083957672119140625,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2023-06-24 16:28:03',
    bandera: 1,
    wikiDataId: 'Q16579'
  },
  {
    id: 3205,
    nombre: 'Artigas',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 1,
    iso2: 'AR',
    tipo: null,
    latitud: -30.617511199999999149667928577400743961334228515625,
    longitud: -56.95945590000000180452843778766691684722900390625,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: 'Q16603'
  },
  {
    id: 3206,
    nombre: 'Maldonado',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 9,
    iso2: 'MA',
    tipo: null,
    latitud: -34.55979320000000143409124575555324554443359375,
    longitud: -54.8628551999999984900568961165845394134521484375,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: 'Q331196'
  },
  {
    id: 3207,
    nombre: 'Rivera',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 13,
    iso2: 'RV',
    tipo: null,
    latitud: -31.481742100000001727266862872056663036346435546875,
    longitud: -55.24357590000000328700480167753994464874267578125,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: 'Q16609'
  },
  {
    id: 3208,
    nombre: 'Colonia',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 4,
    iso2: 'CO',
    tipo: null,
    latitud: -34.1294678000000004658431862480938434600830078125,
    longitud: -57.6605184000000008381903171539306640625,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: 'Q16580'
  },
  {
    id: 3209,
    nombre: 'Durazno',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 5,
    iso2: 'DU',
    tipo: null,
    latitud: -33.02324540000000041573002818040549755096435546875,
    longitud: -56.02846439999999716974343755282461643218994140625,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: 'Q16591'
  },
  {
    id: 3210,
    nombre: 'Río Negro',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 12,
    iso2: 'RN',
    tipo: null,
    latitud: -32.76763559999999841920725884847342967987060546875,
    longitud: -57.4295206999999976460458128713071346282958984375,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: 'Q16596'
  },
  {
    id: 3211,
    nombre: 'Cerro Largo',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 3,
    iso2: 'CL',
    tipo: null,
    latitud: -32.4411032000000005837136995978653430938720703125,
    longitud: -54.35217529999999896972440183162689208984375,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: 'Q16575'
  },
  {
    id: 3212,
    nombre: 'Paysandú',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 11,
    iso2: 'PA',
    tipo: null,
    latitud: -32.0667365999999987025148584507405757904052734375,
    longitud: -57.336478900000003022796590812504291534423828125,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: 'Q16576'
  },
  {
    id: 3213,
    nombre: 'Canelones',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 2,
    iso2: 'CA',
    tipo: null,
    latitud: -34.540871699999996735641616396605968475341796875,
    longitud: -55.93075999999999936562744551338255405426025390625,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:31:43',
    bandera: 1,
    wikiDataId: ''
  },
  {
    id: 3214,
    nombre: 'Treinta y Tres',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 19,
    iso2: 'TT',
    tipo: null,
    latitud: -33.068508600000001251828507520258426666259765625,
    longitud: -54.28586270000000268964868155308067798614501953125,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:32:19',
    bandera: 1,
    wikiDataId: 'Q16610'
  },
  {
    id: 3215,
    nombre: 'Lavalleja',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 8,
    iso2: 'LA',
    tipo: null,
    latitud: -33.9226175000000012005330063402652740478515625,
    longitud: -54.97657939999999854308043722994625568389892578125,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:32:19',
    bandera: 1,
    wikiDataId: 'Q460435'
  },
  {
    id: 3216,
    nombre: 'Rocha',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 14,
    iso2: 'RO',
    tipo: null,
    latitud: -33.96900810000000348054527421481907367706298828125,
    longitud: -54.0214849999999984220266924239695072174072265625,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:32:19',
    bandera: 1,
    wikiDataId: 'Q16582'
  },
  {
    id: 3217,
    nombre: 'Florida',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 7,
    iso2: 'FD',
    tipo: null,
    latitud: -34.1008393999999981360815581865608692169189453125,
    longitud: -56.20656960000000168520273291505873203277587890625,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2023-06-24 16:27:18',
    bandera: 1,
    wikiDataId: 'Q16593'
  },
  {
    id: 3218,
    nombre: 'Montevideo',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 10,
    iso2: 'MO',
    tipo: null,
    latitud: -34.818158699999997907070792280137538909912109375,
    longitud: -56.21382559999999983801899361424148082733154296875,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:32:19',
    bandera: 1,
    wikiDataId: 'Q16594'
  },
  {
    id: 3219,
    nombre: 'Soriano',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 17,
    iso2: 'SO',
    tipo: null,
    latitud: -33.5102791999999993777237250469624996185302734375,
    longitud: -57.74981030000000004065441316924989223480224609375,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:32:19',
    bandera: 1,
    wikiDataId: 'Q16584'
  },
  {
    id: 3220,
    nombre: 'Salto',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 15,
    iso2: 'SA',
    tipo: null,
    latitud: -31.38802799999999848523657419718801975250244140625,
    longitud: -57.96124549999999686633600504137575626373291015625,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:32:19',
    bandera: 1,
    wikiDataId: 'Q16595'
  },
  {
    id: 3221,
    nombre: 'Tacuarembó',
    id_pais: 235,
    codigo_pais: 'UY',
    codigo_fips: 18,
    iso2: 'TA',
    tipo: null,
    latitud: -31.720683699999998594876160495914518833160400390625,
    longitud: -55.98598870000000005120455170981585979461669921875,
    created_at: '2019-10-05 12:48:53',
    updated_at: '2021-12-03 16:32:19',
    bandera: 1,
    wikiDataId: 'Q16587'
  }
]

const Mapa = ({ personas }) => {
  // Estado para almacenar el conteo de personas por departamento
  const [conteoPorDepartamento, setConteoPorDepartamento] = useState({});

  // Función para calcular el conteo de personas por departamento
  const calcularConteoPorDepartamento = () => {
    const conteo = {};

    personas.forEach((persona) => {
      console.log(persona)
      const departamentoPersona = persona.departamento.toString();
      conteo[departamentoPersona] = (conteo[departamentoPersona] || 0) + 1;
    });    

    setConteoPorDepartamento(conteo);
  };

  useEffect(() => {
    // Calcular el conteo de personas por departamento cuando el array de personas cambie
    calcularConteoPorDepartamento();
  }, [personas]);

  return (
    <>
      <MapContainer
        center={[-32.81461649853357, -55.927466908701255]}
        zoom={7}
        scrollWheelZoom={false}
        style={{ width: '100%', height: '600px' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {departamentos.map(({ latitud, longitud, nombre, codigo_fips, id }) => (
          <Marker key={codigo_fips} position={[latitud, longitud]}>
            <Popup>
              Total censados en {nombre}: {conteoPorDepartamento[id] || 0}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </>
  );
};

export default Mapa;


