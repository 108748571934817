import ReactApexChart from 'react-apexcharts';

const Bar = ({ ...props }) => {



  const categories = [];
  const dataSeries = [];

  Object.values(props).forEach((value, index) => {
    if (value !== 0) {
      categories.push(Object.keys(props)[index]);
      dataSeries.push(value);
    }
  });

  const data = {
    series: [
      {
        data: dataSeries,
      },
    ],
    options: {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          style: {
            colors: '#fff', 
          },
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: '#fff', 
          },
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart options={data.options} series={data.series} type='bar' height={210} />
    </div>
  );
};

export default Bar;

