import ReactApexChart from 'react-apexcharts'

const Pie = ({ Dependiente, Independiente, Público, Comerciante, Estudiante, Emprendedor, No_trabaja }) => {
  
  const data = {
    series: [Dependiente, Independiente, Público, Comerciante, Estudiante, Emprendedor, No_trabaja ],
    options: {
      chart: {
        height: 350,
        type: 'pie'
      },
      labels: ['Dependiente','Independiente','Público','Comerciante','Estudiante','Emprendedor','No trabaja'],
      legend: {
        position: 'bottom'
      }
    }
  }
  return (
    <div id='chart'>
      <ReactApexChart options={data.options} series={data.series} type='pie' />
    </div>
  )
}

export default Pie
