// persona.jsx

import React, { useState } from 'react';
import Alert from '../../../UI/Alert';
import Button from '../../../UI/Button';
import { fetchEliminarPersona } from '../../../../services/censoAPI';
import CensadosTotales from './CensadosTotales';

const Personas = ({ personas, setPersonas, idCensoGuardado, filtroOcupacion, totalPersonasEnLaApp  }) => {

 

  const [message, setMessage] = useState('');
  const [classColor, setClassColor] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const totalCensados = personas.length;
  const totalCensadosEnMontevideo = personas.filter((persona) => persona.departamento === '3218');
  const totalCensadosEnMontevideoTotal = totalCensadosEnMontevideo.length;

  const _handleEliminarPersona = async (idCenso) => {
    try {
      const response = await fetchEliminarPersona(idCenso);

      setShowAlert(true);
      setMessage('Persona eliminada exitosamente');
      setClassColor('success');

      // Filtramos el array para eliminar solo la persona con el idCenso proporcionado
      setPersonas(prevPersonas => prevPersonas.filter(persona => persona.idCenso !== idCenso));

    } catch (error) {
      setShowAlert(true);
      setMessage('Ha ocurrido un error al eliminar la persona');
      setClassColor('danger');
    }
  };

  return (
    <>
    <div className='col-9'>
      
      {showAlert ? <Alert classColor={classColor} message={message} /> : ''}
      <table className='table table-striped'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>Nombre</th>
            <th scope='col'>Departamento</th>
            <th scope='col'>Ciudad</th>
            <th scope='col'>Fecha de nacimiento</th>
            <th scope='col'>Ocupación</th>
            <th scope='col'>Eliminar</th>
          </tr>
        </thead>
        <tbody>
        {personas.length > 0 ? (
            personas
              .filter(
               
                (persona) =>
                  !filtroOcupacion || persona.ocupacion === filtroOcupacion
              )
              .map((persona) => (
                <tr key={persona.id}>
                  <td>{persona.nombre}</td>
                  <td>{persona.departamento}</td>
                  <td>{persona.ciudad}</td>
                  <td>{persona.fechaNacimiento}</td>
                  <td>{persona.ocupacion}</td>
                  <td>
                    <Button
                      classColor={'btn btn-sm btn-danger'}
                      cta={'Eliminar'}
                      onHandleClick={() => _handleEliminarPersona(persona.idCenso)}
                    />
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan='6'>
                <Alert
                  classColor={'info'}
                  message={'Aún no tienes personas censadas'}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    <CensadosTotales totalCensados={personas.length} totalCensadosEnMontevideo={totalCensadosEnMontevideoTotal} totalPersonasEnLaApp={totalPersonasEnLaApp} />
    </>
  );
};

export default Personas;
