import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PieTotalCenso = ({ totalCensados, totalPersonasEnLaApp }) => {
    
  const data = {
    
    series: [totalCensados, totalPersonasEnLaApp],
            options: {
              chart: {
                height: 350,
                type: 'radialBar',
              },
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      fontSize: '16px',
                      color: '#fff'
                    },
                    value: {
                      fontSize: '20px',
                      color: '#fff'
                    },
                    total: {
                      show: false,
                      label: 'Total',
                      formatter: function (w) {
                        return 
                      }
                    }
                  }
                }
              },
              labels: ['Por vos', 'Total Censados'],
            },
        }

  return (
    <div id='chart'>
      <ReactApexChart options={data.options} series={data.series} type="radialBar" height={350} />
    </div>
  );
};

export default PieTotalCenso;


