import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LoginForm from './LoginForm'
import './Login.css'

const Login = ({onLogin, user}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if(user){
      navigate("/dashboard")
    }  
  }, [user,navigate])

  
  return (
    <section className='justify-content-center d-flex min-vh-100 align-items-center '>
        <div className='card p-3 border box-shadow col-3 radius'>
            <h3 className='primary-color text-center pt-3'><strong>Iniciar Sesión</strong></h3>
            <section className='card-body'>
            <LoginForm onLogin={onLogin}/>
            <Link className='d-block mt-3 text-center' to={'/register'}>¿No tienes cuenta?</Link>
            </section>
        </div>
    </section>
  )
}

export default Login