import { setUserToLocalStorage } from "../utils/storage";
import { getItemFromLocalStorage } from "../utils/storage";

const BASE_URL = 'https://censo.develotion.com';


const fetchLogin = async (username, password) => {
  
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      usuario: username,
      password: password,
    }),
  };

  try {
    const response = await fetch(`${BASE_URL}/login.php`, requestOptions);
    if (response.status === 200) {
      return response.json().then((data) => {
        const { apiKey, id } = data;
        setUserToLocalStorage({apiKey, id})
        return Promise.resolve({
          userData: {apiKey, id}
        });
      });
    }

    return Promise.reject({
      code: response.status,
      message: 'Ha ocurrido un error',
    });
  } catch (error) {
    return Promise.reject({
      message: error,
    });
  }
};


const keyEidLocalStorage =  getItemFromLocalStorage("ObligatorioAppUser")




const fetchObtenerDepartamentos = async(userData) => {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'iduser': userData.id,
      'apikey': userData.apiKey,
    }
  };
  try{
    const response = await fetch(`${BASE_URL}/departamentos.php`, requestOptions )
    if (!response.ok) {
      throw new Error('Respuesta no exitosa');
    }
    return response.json();
  } catch(e) {
    return Promise.reject(
      {
        message: 'Ha ocurrido un error',
      }
    );
  }  
};

const fetchObtenerCuidadesDelDepto = async (idDepartamento, userData) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "apikey": userData.apiKey,
      "iduser": userData.id,
    }
  };
  try {
    const response = await fetch(`${BASE_URL}/ciudades.php?idDepartamento=${idDepartamento}`, requestOptions);
    if (!response.ok) {
      throw new Error('Respuesta no exitosa');
    }
    return response.json();
  } catch (e) {
    return Promise.reject({
      message: 'Ha ocurrido un error',
    });
  }
};

const fetchObtenerOcupaciones = async(userData) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'iduser': userData.id,
      'apikey': userData.apiKey,
    }
  };
  try{
    const response = await fetch(`${BASE_URL}/ocupaciones.php`, requestOptions )
    if (!response.ok) {
      throw new Error('Respuesta no exitosa');
    }
    return response.json();
  } catch(e) {
    return Promise.reject(
      {
        message: 'Ha ocurrido un error',
      }
    );
  }  
};



const fetchAgregarPersona = async ( nombre, departamento, ciudad, fechaNacimiento, ocupacion, userData) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("apikey", userData.apiKey);
  myHeaders.append("iduser", userData.id); 

  const raw = JSON.stringify({
    idUsuario: userData.id, 
    nombre: nombre,
    departamento: departamento,
    ciudad: ciudad,
    fechaNacimiento: fechaNacimiento,
    ocupacion: ocupacion,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(`${BASE_URL}/personas.php`, requestOptions);
    if (!response.ok) {
      throw new Error('Respuesta no exitosa');
    }
    return response.json();
  } catch (e) {
    return Promise.reject({
      message: 'Ha ocurrido un error',
    });
  }
};


const fetchRegistroPersona = async (usuario, password) => {
  
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");  

  const raw = JSON.stringify({
    usuario: usuario, 
    password: password,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(`${BASE_URL}/usuarios.php`, requestOptions);
    if (!response.ok) {
      throw new Error('Respuesta no exitosa');
    }
    return response.json();
  } catch (e) {
    return Promise.reject({      
      message: "Ese usuario ya existe"
    });
  }
};



const fetchEliminarPersona = async (idCenso) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("apikey", keyEidLocalStorage.apiKey);
  myHeaders.append("iduser", keyEidLocalStorage.id);   

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const response = await fetch(`${BASE_URL}/personas.php?idUsuario=${idCenso}`, requestOptions);
    if (!response.ok) {
      throw new Error('Respuesta no exitosa');
    }
    return response.json();
  } catch (e) {
    return Promise.reject({
      message: 'Ha ocurrido un error',
    });
  }
};

const fetchObtenerTotalCensadosEnLaApp = async(userData) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'iduser': userData.id,
      'apikey': userData.apiKey,
    }
  };
  try{
    const response = await fetch(`${BASE_URL}/totalCensados.php`, requestOptions )
    if (!response.ok) {
      throw new Error('Respuesta no exitosa');
    }
    return response.json();
  } catch(e) {
    return Promise.reject(
      {
        message: 'Ha ocurrido un error',
      }
    );
  }  
};





export { 
  fetchLogin,
  fetchObtenerDepartamentos,
  fetchObtenerCuidadesDelDepto,
  fetchObtenerOcupaciones,
  fetchAgregarPersona,
  fetchEliminarPersona,
  fetchObtenerTotalCensadosEnLaApp,
  fetchRegistroPersona
};
