import React, { useEffect, useState } from 'react';
import Header from './Header';
import NewPerson from '../NewPerson';
import Personas from './Personas';
import Charts from './Charts';
import Tiempo from './Tiempo';
import Mapa from './Mapa';
import { getItemFromLocalStorage, setUserToLocalStorage } from '../../../utils/storage';

import { fetchObtenerOcupaciones, fetchObtenerPersonas, fetchObtenerDepartamentos, fetchObtenerCuidadesDelDepto, fetchObtenerTotalCensadosEnLaApp } from '../../../services/censoAPI';

export const Dashboard = ({ userLogged, onLogout }) => {
  

  const [obtengoDeptos, setObtenerDeptos] = useState([]);
  const [obtengoCiudadesDepto, setObtengoCiudadesDepto] = useState([]);
  const [obtengoOcupaciones, setObtengoOcupaciones] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState('');
  const [personas, setPersonas] = useState([]);
  const [totalPersonasEnLaApp, setTotalPersonasEnLaApp] = useState([]);

  //filtro ocupacion
  const [filtroOcupacion, setFiltroOcupacion] = useState('');

  const handleFiltroOcupacionChange = (event) => {
    setFiltroOcupacion(event.target.value);
  };

  

  useEffect(() => {

    const {userData} = userLogged;
    

    if (userData) {
      setUserToLocalStorage(userData)
      fetchObtenerDepartamentos(userData)
        
        .then((data) => {
          console.log(data.departamentos);
          setObtenerDeptos(data.departamentos || []);
        })
        .catch((e) => {
          console.log("Error al obtener departamentos:", e);
        });
  
      fetchObtenerOcupaciones(userData)
        .then((data) => {
          console.log(data.ocupaciones);
          setObtengoOcupaciones(data.ocupaciones || []);
        })
        .catch((e) => {
          console.log("Error al obtener ocupaciones:", e);
        });
  
      fetchObtenerTotalCensadosEnLaApp(userData)
        .then((data) => {
          setTotalPersonasEnLaApp(data.total);
        })
        .catch((e) => {
          console.log("Error al obtener total censados:", e);
        });
    }
  }, [userLogged]);

  const handleDepartamentoChange = (event) => {

    const {userData} = userLogged;

     

    const selectedDeptId = event.target.value;
    setSelectedDepartamento(selectedDeptId);

    fetchObtenerCuidadesDelDepto(selectedDeptId, userData)
      .then((data) => {        
        setObtengoCiudadesDepto(data.ciudades || []);
      })
      .catch((e) => {
        console.log("Error al obtener ciudades:", e);
      });
  };

  const handleAgregarPersona = (persona) => {
    setPersonas([...personas, persona]);
  };

  return (
    <>
      <Header onLogout={onLogout} />
      <section className='container'>
        <article className='row mx-auto'>
          <div className='col-5'>
            <h1 className='primary-color mb-4'><strong>Censo Nacional 2023: Explorando Nuestra Sociedad</strong></h1>
            <p className='mb-3'>Te damos la bienvenida al Censo Nacional 2023 de Uruguay. Este año,
            estaremos recopilando información sobre edades y ocupaciones en todos los departamentos del país.
            A través de estos datos, crearemos gráficos informativos que destacarán patrones demográficos y tendencias laborales,
            brindándonos una visión más completa de nuestra sociedad diversa y dinámica.</p>
            <p className='mb-3'>Tu participación es esencial para el éxito de este censo.
            Al proporcionar detalles sobre tu edad y ocupación, estarás contribuyendo a la construcción de un panorama detallado de la vida en Uruguay.
            Los resultados nos ayudarán a tomar decisiones informadas y a trazar un camino sólido hacia el futuro.</p>
            <p className='mb-3'>¡Gracias por ser parte de este importante proyecto!</p>
          </div>
          <div className='col-5 offset-2'>
            <NewPerson
              userLogged={userLogged}
              obtengoDeptos={obtengoDeptos}
              obtengoOcupaciones={obtengoOcupaciones}
              obtengoCiudadesDepto={obtengoCiudadesDepto}
              handleDepartamentoChange={handleDepartamentoChange}
              updatePersonList={handleAgregarPersona}
            />
          </div>
        </article>
      </section>
        
      <section className='container bg-color'> 
        <article className='row mx-auto'>
          <h2 className='text-white text-center d-block mb-4 col-12'><strong>Resultados de los usuarios</strong></h2>
          <div className='row justify-content-between'>
              <select
                className='form-control col-3'
                onChange={handleFiltroOcupacionChange}
                value={filtroOcupacion}
              >
                <option value=''>Todas las ocupaciones</option>
                {obtengoOcupaciones.map((ocupacion) => (
                  <option key={ocupacion.id} value={ocupacion.nombre}>
                    {ocupacion.ocupacion}
                  </option>
                ))}
              </select>
              <Personas
                personas={personas}
                setPersonas={setPersonas}
                filtroOcupacion={filtroOcupacion}
                totalPersonasEnLaApp={totalPersonasEnLaApp}
              />
              <Charts personas={personas}/> 
             <Tiempo />            
          </div>
        </article>
      </section>
      <section className='row container mx-auto pb-0 bg-color text-white'>
        <h2 className='text-center d-block mb-3 col-12'><strong>Censados por departamento</strong></h2>
        <p className='text-center mb-5 d-block mx-auto'>Has click en cada punto del mapa, para saber cuantos censados hay,<br></br> se irá refrescando a medida que vayas censando personas</p>
        <Mapa personas={personas} />
      </section>
    </>
  );
};

export default Dashboard;
