import React, { useRef, useState } from 'react'
import Button from '../../../UI/Button';
import Alert from '../../../UI/Alert';
import { fetchRegistroPersona } from '../../../../services/censoAPI';



const RegisterForm = ({onRegister}) => {

  const [message, setMessage] = useState('')
  const [classColor, setClassColor] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const inputUserUsuario = useRef();
  const inputUserPassword = useRef();

  const _onHandleRegister = (e) =>{
    e.preventDefault();
    if(!_isEmptyForm()){      
      setShowAlert(true);
      fetchRegistroPersona(inputUserUsuario.current.value, inputUserPassword.current.value).then(userData => {        
        setMessage("Registro existoso")
        setClassColor("success")        
       
      }).catch( e => {
        setShowAlert(true);
        setMessage(e.message)
        setClassColor("danger")
      })
    } else{
      setShowAlert(true);
      setMessage("Por favor complete los campos")
      setClassColor("danger")
    }    
  }

  const _isEmptyForm = () => {
    return (inputUserUsuario.current.value.length === 0 || inputUserPassword.current.value.length === 0)
  }

  const _onHandleChange = () => {
    if(!_isEmptyForm()){
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  }

  return (
    <>
      <form>
        
        <div className="form-group">          
          <input type="text" className="form-control" placeholder="Usuario" ref={inputUserUsuario} onChange={_onHandleChange} />
        </div>
        <div className="form-group">          
          <input type="password" className="form-control" placeholder="Contraseña" ref={inputUserPassword} onChange={_onHandleChange} />
        </div>
        {showAlert ? <Alert classColor={classColor} message={message} /> : ''}
        <Button cta={"Registrarme"} classColor={"button-color mt-3 w-100"} onHandleClick={_onHandleRegister} disabled={btnDisabled} />        
      </form>
      
      
    </>
  );
};

export default RegisterForm